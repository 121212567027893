import { useEffect } from "react";
import { pdfjs } from "react-pdf";

export const usePdfWorker = () => {
  let hasRun = false;

  /*
   * Side Effect:
   *  Load the pdf worker when the hook is run. This will only run once.
   */
  useEffect(() => {
    // Load the worker
    if (!hasRun) {
      try {
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
          "pdfjs-dist/build/pdf.worker.min.mjs",
          import.meta.url
        ).toString();
      } catch (e) {
        console.error("Error loading pdf worker", e);
      }
      hasRun = true;
    }
  }, []);
};
