import { LoadingSpinner } from "@athena/components";
import styled from "@emotion/styled";
import { Tabs, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PageNameContext } from "src/App";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { LinkTab } from "src/shared/linkTab/LinkTab";
import Menu from "../menu/Menu";

export const Admin = () => {
  const location = useLocation();
  const pathSections = location.pathname.split("/");
  const lastSection = pathSections[pathSections.length - 1];
  const [selectedTab, setSelectedTab] = useState(lastSection || "general");
  const { setPageName } = useContext(PageNameContext);

  const { userData } = useCurrentUser();
  const { user, organisation } = userData || {};

  useEffect(() => {
    setPageName("Admin");
  }, [setPageName]);

  return (
    <Menu>
      {!user || !organisation ? (
        <LoadingContainerFull>
          <LoadingSpinner />
          <p>Loading Admin Panel...</p>
        </LoadingContainerFull>
      ) : user.roles.includes("admin") ? (
        <Box sx={{ flexGrow: 1, mb: 2 }}>
          <Container
            sx={{
              marginTop: 3,
              maxWidth: "1800px",
            }}
            maxWidth={false}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{ flexGrow: 1, marginBottom: 3 }}
            >
              Admin Settings
            </Typography>

            <Box sx={{ borderBottom: 2, borderColor: "divider", mb: 3 }}>
              <Tabs
                value={selectedTab}
                onChange={(_, newTab) => {
                  setSelectedTab(newTab);
                }}
                aria-label="basic tabs example"
                variant="fullWidth"
                sx={{ width: "100%" }}
              >
                {["insurer"].includes(organisation?.orgType) && (
                  <>
                    <LinkTab
                      label="General"
                      value={"general"}
                      link={"general"}
                      sx={{ flex: "1", maxWidth: "initial" }}
                    />
                    <LinkTab
                      label="Users"
                      value={"users"}
                      link={"users"}
                      sx={{ flex: "1", maxWidth: "initial", minWidth: "250px" }}
                    />
                  </>
                )}
                {["loss-adjuster"].includes(organisation?.orgType) && (
                  <>
                    <LinkTab
                      label="General"
                      value={"general"}
                      link={"general"}
                      sx={{ flex: "1", maxWidth: "initial" }}
                    />
                  </>
                )}
                {organisation?.orgType === "engineering" && (
                  <>
                    <LinkTab
                      label="General"
                      value={"general"}
                      link={"general"}
                      sx={{ flex: "1", maxWidth: "initial" }}
                    />
                    <LinkTab
                      label="Users"
                      value={"users"}
                      link={"users"}
                      sx={{ flex: "1", maxWidth: "initial" }}
                    />

                    <LinkTab
                      label="Report Templates"
                      value={"reportTemplates"}
                      link={"reportTemplates"}
                      sx={{ flex: "1", maxWidth: "initial" }}
                    />
                    <LinkTab
                      label="Office Capacity"
                      value={"office-capacity"}
                      link={"office-capacity"}
                      sx={{ flex: "1", maxWidth: "initial" }}
                    />
                  </>
                )}
              </Tabs>
            </Box>
            <Outlet />
          </Container>
        </Box>
      ) : (
        <LoadingContainerFull>
          <LoadingSpinner />
          <p>You are not authorized to access this page</p>
        </LoadingContainerFull>
      )}
    </Menu>
  );
};

const LoadingContainerFull = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 70vh;
`;
